import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import Axios from 'axios';

const deleteRede = (id_rede, e) => {
  e.preventDefault();
  let text = "Tem a certeza que pretende elimninar esta rede?";
  if (window.confirm(text) == true) {
    Axios.post(`https://social.blisq.pt/ws/api/rede/deleteRedeById`, { id_rede })
      .then((res) => {
        console.log(res.data);
        if (res.data.Error === false) {
          window.location.reload();
        }
      }).catch((err) => {
        console.log(err);
      });
  } else {
    text = "Cancelado";
  }
}

const ListRedes = () => {
  const { id_cliente } = useParams();
  const [error, setError] = useState(null);
  const [redes, setRedes] = useState([]);
  const [nomeCliente, setCliente] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://social.blisq.pt/ws/api/rede/listRedeByClient?cliente_id=${encodeURIComponent(
        id_cliente
      )}`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.Error === false) {
            console.log(data.Redes);
            setRedes(data.Redes);
            setCliente(data.Cliente);
          } else {
            setError(data.Message);
          }
        },
        (error) => {
          setError(error);
        }
      );
  }, []);

  if (error) {
    return (
      <div>
        <HeaderMenu />
        <h2 className="errorListing">{error}</h2>;
        <button onClick={() => navigate(`/InsertNewRede/${id_cliente}`)} className="circle-floating-button" title="Adicionar nova tarefa">
            <FontAwesomeIcon icon={faPlus} className="icon-add" />
          </button>

      </div>
    )
  } else {
    return (
      <div>
        <HeaderMenu />
        <div className="container">
          <h1> Redes de {nomeCliente}</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Cliente - Rede</th>
                <th>Rede Social</th>
                <th>Tarefas Diárias</th>
                <th>Tarefas Mensais</th>
                <th>Tarefas Trimestrais</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {redes.map((rede) => (
                <tr key={rede.id_rede}>
                  <td>{rede.nomeRede}</td>
                  <td>{rede.redeSocial}</td>
                  <td>
                    <a onClick={() => navigate(`/ListTarefaDiaria/${rede.id_rede}`)} title="Ver Tarefas Diárias">
                      <FontAwesomeIcon className="eyeIcon" icon={faEye} />
                    </a>
                  </td>
                  <td>
                    <a onClick={() => navigate(`/ListTarefaMensal/${rede.id_rede}`)} title="Ver Tarefas Mensais">
                      <FontAwesomeIcon className="eyeIcon" icon={faEye} />
                    </a>
                  </td>
                  <td>
                    <a onClick={() => navigate(`/ListTarefaTrimestral/${rede.id_rede}`)} title="Ver Tarefas Trimestrais">
                      <FontAwesomeIcon className="eyeIcon" icon={faEye} />
                    </a>
                  </td>
                  <td>
                    <button onClick={(e) => deleteRede(rede.id_rede, e)} title="Eliminar Rede">
                      <FontAwesomeIcon className="trashIcon" icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button onClick={() => navigate(`/InsertNewRede/${id_cliente}`)} className="circle-floating-button" title="Adicionar nova tarefa">
            <FontAwesomeIcon icon={faPlus} className="icon-add" />
          </button>
        </div>

      </div>
    );
  }
};

export default ListRedes;
