import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import HeaderMenu from './HeaderMenu';

const InsertTarefaDiaria = () => {
    const navigate = useNavigate();
    const { id_rede } = useParams();
    const [error, setError] = useState(null);
    const [years, setYears] = useState([]);
    console.log(id_rede);


    useEffect(() => {
        fetch("https://social.blisq.pt/ws/api/anos/getAnos")
            .then(res => res.json())
            .then(data => {
                if (data.Error === false) {
                    setYears(data.Anos);
                } else {
                    setError(data.Message);
                }
            })
            .catch(error => {
                setError(error);
            });
    }, []);

    const handleSubmit = event => {
        event.preventDefault();
        var { name } = document.forms[0];
        var { description } = document.forms[0];
        var { dia } = document.forms[0];
        var { mes } = document.forms[0];
        var { ano } = document.forms[0];

        var valorAno = ano.options[ano.selectedIndex].text;

        if (((mes.value == 4 || mes.value == 6 || mes.value == 9 || mes.value == 11) && dia.value > 30)) {
            setError("O dia introduzido não é válido1");
            return;
        } else if (mes.value == 2 && dia.value > 28) {
            setError("O dia introduzido não é válido2");
            return;
        } else if (mes.value == 2 && dia.value > 29 && ((valorAno % 4 == 0 && valorAno % 100 != 0) || valorAno % 400 == 0)) {
            setError("O dia introduzido é inválido3");
            return;
        }
            fetch("https://social.blisq.pt/ws/api/tarefas/insertTarefaDiaria", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    name: name.value,
                    description: description.value,
                    day: dia.value,
                    rede_id: id_rede,
                    estado_id: 1,
                    mes_id: mes.value,
                    ano_id: ano.value,
                })
            })
                .then(res => res.json())
                .then(data => {
                    if (data.Error === false) {
                        alert("Tarefa inserida");
                        navigate(-1);
                    } else {
                        console.log(data.Message);
                        setError(data.Message);
                    }
                })
                .catch(error => {
                    setError(error);
                });


        console.log(error);


    };


    return (
        <div>
            <HeaderMenu />
            <div className='mg-all-100'>
                <h1>Nova Tarefa Diária</h1>
                <br />
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nome da tarefa: </label>
                    <input type="text" name="name" required />
                </div>
                <p></p>
                <div>
                    <label>Descrição da tarefa:</label>
                    <input type="text" name="description" required />
                </div>
                <br />
                <div>
                    <label>Dia: </label>
                    <input type="number" id="dia" name="dia" min="1" max="31"/>
                </div>
                <br />
                <div>
                    <label>Mês: </label>
                    <select name="mes" id="mes">
                        <option value="1">Janeiro</option>
                        <option value="2">Fevereiro</option>
                        <option value="3">Março</option>
                        <option value="4">Abril</option>
                        <option value="5">Maio</option>
                        <option value="6">Junho</option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                    </select>
                </div>
                <br />
                <div>
                    <label>Ano: </label>
                    <select id="ano" name="ano">
                        {years.map(year => (
                            <option key={year.id_ano} value={year.id_ano}>{year.value}</option>
                        ))}
                    </select>
                </div>
                <br />
                <div className="button-container">
                    <input type="submit" />
                </div>
            </form>
            </div>
        </div>
    );
};

export default InsertTarefaDiaria;
