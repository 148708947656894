import React, { useEffect, useState } from "react";
import HeaderMenu from "./HeaderMenu";
import { useParams, useNavigate } from "react-router-dom";


const InsertNewRede = () => {
    const navigate = useNavigate();
    const {id_cliente} = useParams();
    const [redesSociais, setRedesSociais] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://social.blisq.pt/ws/api/rede/getRedesSociais")
        .then(res => res.json())
        .then(data => {
            console.log(data);
            if(data.Error === false){
                setRedesSociais(data.RedesSociais);
            }else {
                setError(data.Message);
            }
        })
        .catch(error => {
            setError(error);
        })
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        var {name} = document.forms[0];
        var {redeSocial} = document.forms[0];

        console.log(name.value);
        console.log(redeSocial.value);
        console.log(id_cliente);

        fetch("https://social.blisq.pt/ws/api/rede/insertRede", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                nome: name.value,
                cliente_id: id_cliente,
                rede_social_id: redeSocial.value,
            })
        })
        .then(res => res.json())
        .then(data => {
            if(data.Error === false){
                alert("Rede inserida com sucesso");
                navigate(`/ListRedes/${id_cliente}`);
            } else {
                console.log(data.Message);
                setError(data.Message);
            }
        })
        .catch(error => {
            setError(error);
        });
    }


    return(
        <div>
            <HeaderMenu/>
            <div className="mg-all-100">
                <h1>Nova Rede</h1>
                <form onSubmit={handleSubmit}>
                    <br />
                    <label>Nome da rede: </label>
                    <input type="text" name="name" required />
                    <br />
                    <br />
                    <label>Rede Social: </label>
                    <select name="redeSocial" id="redeSocial">
                        {redesSociais.map(rede => (
                            <option key={rede.id_rede_social} value={rede.id_rede_social}>{rede.value}</option>
                        ))}
                    </select>
                    <br />
                    <br />
                    <div className="button-container">
                        <input type="submit" />
                    </div>
                </form>
            </div>
        </div>  
    );

}


export default InsertNewRede;