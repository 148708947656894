import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faEye } from "@fortawesome/free-solid-svg-icons";
import Axios from 'axios';


const clientDelete = (id_cliente, e) => {

  e.preventDefault();
  let text = "Tem a certeza que pretende eliminar este cliente?";
  if (window.confirm(text) == true) {
    Axios.post(`https://social.blisq.pt/ws/api/clientes/deleteClient`, { id_cliente })
      .then((res) => {
        console.log(res.data);
        if (res.data.Error === false) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    text = "Cancelado";
  }

};

const ClientList = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [clients, setClients] = useState([]);
  const [myClients, setMyClients] = useState([]);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetch("https://social.blisq.pt/ws/api/clientes/getAllClients")
      .then(res => res.json())
      .then(
        (data) => {
          if (data.Error === false) {
            setIsLoaded(true);
            setClients(data.clients);
          } else {
            setIsLoaded(true);
            setError(data.Message);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    fetch(`https://social.blisq.pt/ws/api/clientes/getClientByUser?id_user=${encodeURIComponent(user.id_user)}`)
      .then(res => res.json())
      .then(
        (data) => {
          if (data.Error === false) {
            setMyClients(data.Clientes);
            console.log(myClients);
          } else {
            setError(data.Message);
          }
        },
        (error) => {
          setError(error);
        }
      )
  }, [])

  if (error) {
    return (
      <div>
        <HeaderMenu />
        <h2 className="errorListing">{error}</h2>;
      </div>
    )
  } else if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div>
        <HeaderMenu />
        <div className="container">
          <h1>Olá, {user.nome}</h1>
          <br />
          <br />
          <br />
          <br />
          <h1>Lista de Clientes</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Gestor Projeto</th>
                <th>Designer Responsável</th>
                <th>Contactos c/ cliente</th>
                <th>Calendarização</th>
                <th>Monitorização</th>
                <th>Design (horas)</th>
                <th>Gestão de Campanhas</th>
                <th>Tempo Total Real (horas)</th>
                <th>Tempo Total Previsto (horas)</th>
                <th>Avença até (inclusive)</th>
                <th>Valor da avença (mensal)</th>
                <th>Investimento em publicidade</th>
                <th>Notas / comentários</th>
                <th>Relatórios (data de envio dos relatórios)</th>
                <th>Redes</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {clients.map(client => (
                <tr key={client.IdCliente}>
                  <td>{client.NomeCliente}</td>
                  <td>{client.NomeGestor}</td>
                  <td>{client.NomeDesigner}</td>
                  <td>{client.ContactosHoras}</td>
                  <td>{client.Calendarizacao}</td>
                  <td>{client.Monitorizacao}</td>
                  <td>{client.DesignHoras}</td>
                  <td>{client.GestaoCampanhas}</td>
                  <td>{client.TempoEstimado}</td>
                  <td>{client.TempoReal}</td>
                  <td>{client.DataAvenca}</td>
                  <td>{client.Avenca}</td>
                  <td>{client.Investimento}</td>
                  <td>{client.Notas}</td>
                  <td>{client.DataEnvioRelatorio}</td>
                  <td>
                    <a onClick={() => navigate(`/ListRedes/${client.IdCliente}`)} title="Ver as redes associadas">
                      <FontAwesomeIcon className="eyeIcon" icon={faEye} />
                    </a>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <a onClick={() => navigate(`/EditClient/${client.IdCliente}`)} title="Editar Cliente">
                        <FontAwesomeIcon className="editIcon" icon={faPenToSquare} />
                      </a>
                      <a onClick={(e) => clientDelete(client.IdCliente, e)} title="Eliminar Cliente">
                        <FontAwesomeIcon className="trashIcon" icon={faTrash} />
                      </a>
                    </div>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <h1>Os meus clientes</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {myClients.map(cliente => (
                <tr key={cliente.idCliente}>
                  <td>{cliente.nome}</td>
                  <td>
                    <a onClick={() => navigate(`/ListRedes/${cliente.idCliente}`)}>
                      <FontAwesomeIcon className="eyeIcon" icon={faEye} />
                    </a>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

export default ClientList;
