import React, { useEffect, useState } from 'react';
import HeaderMenu from './HeaderMenu';


const TarefasConcluidas = () => {
    const [error, setError] = useState(null);
    const [tarefasDiarias, setTarefasDiarias] = useState([]);
    const [tarefasMensais, setTarefasMensais] = useState([]);
    const [tarefasTrimestrais, setTarefasTrimestrais] = useState([]);
    
    useEffect (() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasDiariasConcluidas')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    console.log(data.TarefasDiarias);
                    setTarefasDiarias(data.TarefasDiarias);
                    console.log(tarefasDiarias.length);
                } else {
                    setError(data.Message);
                }
            },
            (error) => {
                setError(error);
            }
        )
    }, [])


    useEffect(() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasMensaisConcluidas')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setTarefasMensais(data.TarefasMensais);
                    console.log(tarefasMensais.length);

                } else {
                    setError(data.Message);
                }
            },
            (error) => {
                setError(error);
            }
        )
    }, [])

    
    useEffect(() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasTrimestraisConcluidas')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setTarefasTrimestrais(data.TarefasTrimestrais);
                    console.log(tarefasTrimestrais.length);

                }else {
                    setError(data.Message);
                }
            },
            (error)  => {
                setError(error);
            }
        )

    }, [])


    if(tarefasDiarias.length == 0 && tarefasMensais.length == 0 && tarefasTrimestrais.length == 0) {
        return (
            <div>
                <HeaderMenu/>
                <div className="errorListing">{error}</div>
            </div>
        )
    }else {
        return(
            <div>
                <HeaderMenu/>
                <div className="flex">
                <div className="container">
                    <h1>Tarefas Diárias Concluídas</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasDiarias.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="container">
                    <h1>Tarefas Mensais Concluídas</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasMensais.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                    <td>{tarefa.Estado}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                </div>
                
                <div className="container">
                    <h1>Tarefas Mensais Concluídas</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasTrimestrais.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                    <td>{tarefa.Estado}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default TarefasConcluidas;