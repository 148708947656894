import React from "react";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";


const HeaderMenu = () => {

  const signOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    signOut();
    navigate("/");
  }

  return (
    <div className="navbar">
      <a href="/ClientList">Home</a>
      <div className="subnav">
        <button className="subnavbtn">Clientes <i className="fa fa-caret-down"></i></button>
        <div className="subnav-content">
          <a href="/InsertClient">Inserir Cliente</a>
        </div>
      </div>
      <div className="subnav">
        <button className="subnavbtn">Tarefas <i className="fa fa-caret-down"></i></button>
        <div className="subnav-content">
          <a href="/TarefasConcluidas">Tarefas Concluídas</a>
          <a href="/TarefasPorFazer">Tarefas Por Fazer</a>
        </div>
      </div>

      <div className="subnav logout">
        <button className="subnavbtn" onClick={() =>logout()}>Logout <i className="fa fa-caret-down"></i></button>
       
      </div>

    </div>
  )

}

export default HeaderMenu;