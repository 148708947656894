import React, { useEffect, useState } from 'react';
import HeaderMenu from "./HeaderMenu";
import { useNavigate } from 'react-router-dom';

const Client = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [designers, setDesigners] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch("https://social.blisq.pt/ws/api/users/getUsers")
            .then(res => res.json())
            .then(data => {
                if (data.Error === false) {
                    setUsers(data.Users);
                    console.log(users);
                } else {
                    setError(data.Message);
                }
            })
            .catch(error => {
                setError(error);
            });
    }, []);


    useEffect(() => {
        fetch("https://social.blisq.pt/ws/api/designer/getAllDesigners")
            .then(res => res.json())
            .then(data => {
                if (data.Error === false) {
                    setDesigners(data.Designers);
                } else {
                    setError(data.Message);
                }
            })
            .catch(error => {
                setError(error);
            });
    }, []);


    const submitClient = (event) => {
        event.preventDefault();

        var { nome } = document.forms[0];
        var { notas } = document.forms[0];
        var { avenca } = document.forms[0];
        var { data_avenca } = document.forms[0];
        var { investimento } = document.forms[0];
        var { gestao_campanhas } = document.forms[0];
        var { horas_design } = document.forms[0];
        var { calendarizacao } = document.forms[0];
        var { contactos } = document.forms[0];
        var { monitorizacao } = document.forms[0];
        var { data_relatorio } = document.forms[0];
        var { tempo_estimado } = document.forms[0];
        var { tempo_real } = document.forms[0];
        var { designer } = document.forms[0];
        var { user } = document.forms[0];

        fetch("https://social.blisq.pt/ws/api/clientes/insertClient", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                nome: nome.value,
                avenca: avenca.value,
                designer_id: designer.value,
                user_id: user.value,
                notas: notas.value,
                investimento: investimento.value,
                data_avenca: data_avenca.value, 
                gestao_campanhas: gestao_campanhas.value,
                design_horas: horas_design.value,
                calendarizacao: calendarizacao.value,
                contactos_cliente: contactos.value,
                monitorizacao: monitorizacao.value,
                data_envio_relatorio: data_relatorio.value,
                tempo_estimado: tempo_estimado.value,
                tempo_real: tempo_real.value
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                console.log(data.Error);
                if (data.Error === false) {
                    alert("Cliente adicionado com sucesso");
                    navigate(-1);
                } else {
                    alert(data.Message);
                }
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    }

    return (
        <div>
            <HeaderMenu />
            <div className="container">
                <h1>Novo cliente</h1>
                <br />
                <form onSubmit={submitClient}>
                    <div>
                        <label>Nome do cliente: </label>
                        <input type="text" name='nome' required />
                    </div>
                    <br />
                    <div>
                        <label>Notas: </label>
                        <input type="text" name='notas'  />
                    </div>
                    <br />
                    <div>
                        <label>Avença: </label>
                        <input type="number" name='avenca' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Data da avença: </label>
                        <input type="date" name='data_avenca' required />
                    </div>
                    <br />
                    <div>
                        <label>Investimento: </label>
                        <input type="number" name='investimento' min={0} step="any" required />
                    </div>
                    <br />
                    <div>
                        <label>Gestao de Campanhas: </label>
                        <input type="number" name='gestao_campanhas' min={0} step="any" required />
                    </div>
                    <br />
                    <div>
                        <label>Horas de Design: </label>
                        <input type="number" name='horas_design' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Calendarização: </label>
                        <input type="number" name='calendarizacao' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Contactos c/ cliente + análise conteúdos + pesquisa + seleção de imagens + planificação mensal + alterações: </label>
                        <input type="number" name='contactos' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Monitorização: </label>
                        <input type="number" name='monitorizacao' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Data de envio do relatório: </label>
                        <input type="date" name='data_relatorio'  />
                    </div>
                    <br />
                    <div>
                        <label>Tempo Estimado: </label>
                        <input type="number" name='tempo_estimado' min={0} step="any"   required />
                    </div>
                    <br />
                    <div>
                        <label>Tempo Real: </label>
                        <input type="number" name='tempo_real' min={0} step="any"  required />
                    </div>
                    <br />
                    <div>
                        <label>Designer: </label>
                        <select name="designer" required>
                            {designers.map((designer, index) => (
                                <option key={index} value={designer.id_designer}>{designer.name}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <label>Utilizador: </label>
                        <select name='user' required>
                            {users.map((user, index) => (
                                <option key={index} value={user.id_user}>{user.Nome}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <div>
                        <input type="submit" />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Client;
