import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faCircleCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import Axios from 'axios';

const editTaskToDo = (id_trimestral, e) => {
    e.preventDefault();

    let text = `Tem a certeza que pretende editar o estado da tarefa para "Por Fazer"?`;
    if (window.confirm(text) == true) {
        Axios.post(`https://social.blisq.pt/ws/api/tarefas/editTarefaTrimestralPorFazer`, { id_trimestral })
            .then((res) => {
                if (res.data.Error === false) {
                    window.location.reload();
                }

            }).catch((err) => {
                console.log(err);
            });
    } else {
        text = "Cancelado";
    }
}

const editTaskDone = (id_trimestral, e) => {
    e.preventDefault();
    let text = `Tem a certeza que pretende editar o estado da tarefa para "Concluído"?`;
    if (window.confirm(text) === true) {
        Axios.post(`https://social.blisq.pt/ws/api/tarefas/editTarefaTrimestralConcluido`, { id_trimestral })
            .then((res) => {
                console.log(res.data);
                if (res.data.Error === false) {
                    window.location.reload();
                }
            }).catch((err) => {
                console.log(err);
            });
    } else {
        text = "Cancelado";
    }

}


const quarterlyTaskDelete = (id_trimestral, e) => {
    e.preventDefault();
    let text = "Tem a certeza que pretende eliminar esta tarefa?";
    if (window.confirm(text) == true) {
        Axios.post(`https://social.blisq.pt/ws/api/tarefas/deleteTarefaTrimestral`, { id_trimestral })
            .then((res) => {
                console.log(res);
                if (res.data.Error === false) {
                    window.location.reload();
                }
            }).catch((err) => {
                console.log(err);
            })
    } else {
        text = "Cancelado";
    }
}



const ListTarefaTrimestral = () => {
    const { id_rede } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [tarefas, setTarefas] = useState([]);
    const navigate = useNavigate();

    const goToAddTask = () => {
        navigate(`/InsertTarefaTrimestral/${id_rede}`);
      };
    

    useEffect(() => {
        fetch(`https://social.blisq.pt/ws/api/tarefas/getTarefaTrimestralByRede?id_rede=${encodeURIComponent(id_rede)}`)
            .then(res => res.json())
            .then(
                (data) => {
                    if (data.Error == false) {
                        setIsLoaded(true);
                        setTarefas(data.TarefaTrimestral);
                        console.log(data);
                    } else {
                        setIsLoaded(true);
                        setError(data.Message);
                    }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])


    if (error) {
        return <div> <HeaderMenu /><div className="errorListing">{error}</div></div>
    } else if (!isLoaded) {
        return <div>Loading...</div>
    } else {
        return (
            <div>
                <HeaderMenu />
                <div className="container">
                    <h1>Tarefas Trimestrais</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da Tarefa</th>
                                <th>Descrição da Tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                                <th>Estado</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefas.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                    <td>{tarefa.Estado}</td>
                                    <td>
                                        <button onClick={() => navigate(`/EditTarefaTrimestral/${tarefa.IdTarefa}`)} title="Editar Tarefa">
                                            <FontAwesomeIcon className="editIcon" icon={faPenToSquare} />
                                        </button>
                                        <button onClick={(e) => quarterlyTaskDelete(tarefa.IdTarefa, e)} title="Eliminar Tarefa">
                                            <FontAwesomeIcon className="trashIcon" icon={faTrash} />
                                        </button>
                                        {
                                            tarefa.Estado === 'Por Fazer' &&
                                            <button onClick={(e) => editTaskDone(tarefa.IdTarefa, e)}>
                                                <FontAwesomeIcon className="checkRed" icon={faCircleCheck} title="Marcar tarefa como concluída" />
                                            </button>
                                        }
                                        {
                                            tarefa.Estado === 'Concluido' &&
                                            <button onClick={(e) => editTaskToDo(tarefa.IdTarefa, e)}>
                                                <FontAwesomeIcon className="checkGreen" icon={faCircleCheck} title="Desmarcar tarefa como concluída" />
                                            </button>
                                        }
                                        <a>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={goToAddTask} className="circle-floating-button" title="Adicionar nova tarefa"> 
                        <FontAwesomeIcon icon={faPlus} className="icon-add" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListTarefaTrimestral;