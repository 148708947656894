import React, { useEffect, useState } from "react";
import {useNavigate } from 'react-router-dom';

import "../css/style.css";
import { useSignIn } from "react-auth-kit";

const MainPage = () => {
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmited, setIsSubmited] = useState(false);
    const [user, setUser] = useState([]);
    const signIn = useSignIn();
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        var { email, pass } = document.forms[0];

        fetch("https://social.blisq.pt/ws/api/users/loginUser", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({ email: email.value, password: pass.value })
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
                console.log(data);
                if(data.Error === false){
                localStorage.setItem('user', JSON.stringify(data.Utilizador));
                    signIn({
                        token: data.token,
                        expiresIn: 3600, 
                        tokenType: "Bearer",
                        authState: {email: data.Utilizador.email},
                        user: data.Utilizador,
                    });
                    navigate('/ClientList');
                }else{
                    alert("E-mail ou password inválidos");
                }
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        //handle do login if = true
    };

    const renderErrorMessage = (name) => name === errorMessages.name && (<div className="error">{errorMessages.message}</div>);



    return (
        <div className="login-form">
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label for="email">E-Mail</label>
                    <input type="text" name="email" id="email" required />
                </div>
                <div className="input-container">
                    <label fir="pass">Password</label>
                    <input type="password" name="pass" id="pass" required />
                </div>
                <div className="button-container">
                    <input id="enter-btn" type="submit" value="Login" />
                </div>
            </form>
        </div>
    );
}


export default MainPage;