import React, { useEffect, useState } from "react";
import HeaderMenu from "./HeaderMenu";
import { useParams, useNavigate } from "react-router-dom";


const EditTarefaMensal = () => {
    const navigate = useNavigate();
    const {id_tarefa} = useParams();
    const [error, setError] = useState(null);
    const [tarefa, setTarefa] = useState(null);
    const [anos, setAnos] = useState([]);

    useEffect(() => {
        fetch("https://social.blisq.pt/ws/api/anos/getAnos")
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setAnos(data.Anos);
                }  else {
                    setError(data.Message);
                }
            },
            (error) => {
                setError(error);
            }
        )
    }, [])

    useEffect(() => {
        fetch(
          `https://social.blisq.pt/ws/api/tarefas/getTarefaMensalById?id_mensal=${encodeURIComponent(
            id_tarefa
          )}`
        )
          .then((res) => res.json())
          .then(
            (data) => {
              if (data.Error === false) {
                console.log(data.tarefaMensal);
                setTarefa(data.tarefaMensal);
              } else {
                setError(data.Message);
              }
            },
            (error) => {
              setError(error);
            }
          );
      }, []);
      
      

      const handleSubmit = event => {
        event.preventDefault();
        var { nome } = document.forms[0];
        var { description } = document.forms[0];
        var { dia } = document.forms[0];
        var { mes } = document.forms[0];
        var { ano } = document.forms[0];
        
        console.log('Nome:' + nome.value);
        console.log('description:' + description.value);
        console.log('dia:' + dia.value);
        console.log('mes:' + mes.value);
        console.log('ano:' + ano.value);

        var valorAno = ano.options[ano.selectedIndex].text;

        if (((mes.value == 4 || mes.value == 6 || mes.value == 9 || mes.value == 11) && dia.value > 30)) {
            setError("O dia introduzido não é válido1");
            return;
        } else if (mes.value == 2 && dia.value > 28) {
            setError("O dia introduzido não é válido2");
            return;
        } else if (mes.value == 2 && dia.value > 29 && ((valorAno % 4 == 0 && valorAno % 100 != 0) || valorAno % 400 == 0)) {
            setError("O dia introduzido é inválido3");
            return;
        }

        fetch("https://social.blisq.pt/api/tarefas/editTarefaMensal", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                id_mensal: id_tarefa,
                name: nome.value,
                description: description.value,
                day: dia.value,
                mes_id: mes.value,
                ano_id: ano.value
            })
        })
        .then(res => res.json())
        .then(data =>{
            if(data.Error === false){
                navigate(-1);
                alert("Tarefa Editada");
                
            } else {
                setError(data.Message);
            }
        })
        .catch(error => {
            setError(error.toString());
        });
    }


    if(error) {
        return <div> <HeaderMenu/> <div className="errorListing">{error}</div> </div>
    } else {
        return (
            <div>
                <HeaderMenu />
            <div className="container">
                <h1>Edição da Tarefa Mensal</h1>
            <form onSubmit={handleSubmit}>
                <br/>
                <div>
                    <label>Nome da tarefa: </label>
                    <input type="text" name="nome" defaultValue={tarefa?.NomeTarefa} required/>
                    <p></p>
                    <div>
                        <label>Descrição da tarefa:</label>
                        <input type="text" name="description" defaultValue={tarefa?.DescricaoTarefa} required/>
                    </div>
                    <p></p>
                    <div>
                        <label>Dia: </label>
                        <input type="number" id="dia" name="dia" min="1" max="31" defaultValue={tarefa?.Dia}/>
                    </div>
                    <p></p>
                    <br/>
                    <div>
                    <label>Mês: </label>
                    <select name="mes" id="mes" >
                            <option value={tarefa?.Mes}>Manter mês</option>
                            <option value="1">Janeiro</option>
                            <option value="2">Fevereiro</option>
                            <option value="3">Março</option>
                            <option value="4">Abril</option>
                            <option value="5">Maio</option>
                            <option value="6">Junho</option>
                            <option value="7">Julho</option>
                            <option value="8">Agosto</option>
                            <option value="9">Setembro</option>
                            <option value="10">Outubro</option>
                            <option value="11">Novembro</option>
                            <option value="12">Dezembro</option>
                    </select>
                    </div>
                    <p></p>
                    <br />
                    <div>
                        <label>Ano: </label>
                        <select name="ano" id="ano">
                            <option value={tarefa?.Ano}>Manter ano</option>
                            {anos.map(ano => (
                                <option value={ano.id_ano}>{ano.value}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <br />

                <input type="submit" />
            </form>
            </div>
          
            </div> 
        )
    }
}

export default EditTarefaMensal;