import React, {useEffect, useState} from 'react';
import HeaderMenu from './HeaderMenu';


const TarefasPorFazer = () => {
    const [error, setError] = useState(null);
    const [tarefasDiarias, setTarefasDiarias] = useState([]);
    const [tarefasMensais, setTarefasMensais] = useState([]);
    const [tarefasTrimestrais, setTarefasTrimestrais] = useState([]);


    useEffect(() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasDiariasPorFazer')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setTarefasDiarias(data.TarefasDiarias);
                } else {
                    setError(data.Message);
                }
            },
            (error) => {
                setError(error);
            }
        )
    }, [])

    useEffect(() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasMensaisPorFazer')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setTarefasMensais(data.TarefasMensais);
                } else {
                    setError(data.Message)
                }
            },
            (error) => {
                setError(error);
            }
        )

    }, [])

    useEffect(() => {
        fetch('https://social.blisq.pt/ws/api/tarefas/getTarefasTrimestraisPorFazer')
        .then(res => res.json())
        .then(
            (data) => {
                if(data.Error === false){
                    setTarefasTrimestrais(data.TarefasTrimestrais);
                }else{
                    setError(data.Message);
                }
            },
            (error) => {
                setError(error);
            }
        )
    }, [])

        return(
            <div>
                <HeaderMenu/>
                <div className="flex">
                <div className="container">
                    <h1>Tarefas Diárias Por Fazer</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasDiarias.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="container">
                    <h1>Tarefas Mensais Por Fazer</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasMensais.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                    <td>{tarefa.Estado}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                </div>
                
                <div className="container">
                    <h1>Tarefas Mensais Por Fazer</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nome da tarefa</th>
                                <th>Descrição da tarefa</th>
                                <th>Dia</th>
                                <th>Mês</th>
                                <th>Ano</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tarefasTrimestrais.map((tarefa) => (
                                <tr key={tarefa.IdTarefa}>
                                    <td>{tarefa.NomeTarefa}</td>
                                    <td>{tarefa.DescricaoTarefa}</td>
                                    <td>{tarefa.Dia}</td>
                                    <td>{tarefa.Mes}</td>
                                    <td>{tarefa.Ano}</td>
                                    <td>{tarefa.Estado}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        )

}

export default TarefasPorFazer;