import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import MainPage from './components/MainPage';
import InsertClient from './components/InsertClient';
import InsertTarefaDiaria from './components/InsertTarefaDiaria';
import InsertTarefaMensal from './components/InsertTarefaMensal';
import InsertTarefaTrimestral from './components/InsertTarefaTrimestral';
import ClientList from './components/ClientList';
import ListTarefaDiaria from './components/ListTarefaDiaria';
import ListTarefaMensal from './components/ListTarefaMensal';
import ListTarefaTrimestral from './components/ListTarefaTrimestral';
import ListRedes from './components/ListRedes';
import EditTarefaDiaria from './components/EditTarefaDiaria';
import EditTarefaMensal from './components/EditTarefaMensal';
import EditTarefaTrimestral from './components/EditTarefaTrimestral';
import TarefasConcluidas from './components/TarefasConcluidas';
import EditClient from './components/EditClient';
import InsertNewRede from './components/InsertNewRede';
import TarefasPorFazer from './components/TarefasPorFazer';
import React, { useEffect } from 'react';
import { RequireAuth } from 'react-auth-kit';

function App() {
  return (

    <Routes>
      <Route exact path="/" element={<MainPage />} />
      <Route path='/ClientList' element={<RequireAuth loginPath="/"><ClientList/></RequireAuth>}/>
      <Route path='/InsertTarefaDiaria' element={<RequireAuth loginPath="/"><InsertTarefaDiaria/></RequireAuth>}/>
      <Route path='/InsertClient' element={<RequireAuth loginPath="/"><InsertClient/></RequireAuth>}/>
      <Route path='/ListRedes/:id_cliente' element={<RequireAuth loginPath="/login"><ListRedes/></RequireAuth>}/>
      <Route path='/ListTarefaDiaria/:id_rede' element={<RequireAuth loginPath="/login"><ListTarefaDiaria/></RequireAuth>}/>
      <Route path='/ListTarefaMensal/:id_rede' element={<RequireAuth loginPath="/login"><ListTarefaMensal/></RequireAuth>}/>
      <Route path='/ListTarefaTrimestral/:id_rede' element={<RequireAuth loginPath="/login"><ListTarefaTrimestral/></RequireAuth>}/>
      <Route path='/EditTarefaDiaria/:id_tarefa' element={<RequireAuth loginPath="/login"><EditTarefaDiaria/></RequireAuth>}/>
      <Route path='/EditTarefaMensal/:id_tarefa' element={<RequireAuth loginPath="/login"><EditTarefaMensal/></RequireAuth>}/>
      <Route path='/EditTarefaTrimestral/:id_tarefa' element={<RequireAuth loginPath="/login"><EditTarefaTrimestral/></RequireAuth>}/>
      <Route path='/InsertTarefaDiaria/:id_rede' element={<RequireAuth loginPath="/login"><InsertTarefaDiaria/></RequireAuth>}/>
      <Route path='/InsertTarefaMensal/:id_rede' element={<RequireAuth loginPath="/login"><InsertTarefaMensal/></RequireAuth>}/>
      <Route path='/InsertTarefaTrimestral/:id_rede' element={<RequireAuth loginPath="/login"><InsertTarefaTrimestral/></RequireAuth>}/>
      <Route path='/EditClient/:id_cliente' element={<RequireAuth loginPath="/login"><EditClient/></RequireAuth>}/>
      <Route path='/TarefasConcluidas' element={<RequireAuth loginPath="/"><TarefasConcluidas/></RequireAuth>}/>
      <Route path='/TarefasPorFazer' element={<RequireAuth loginPath="/"><TarefasPorFazer/></RequireAuth>}/>
      <Route path='/InsertNewRede/:id_cliente' element={<RequireAuth loginPath="/"><InsertNewRede/></RequireAuth>}/>
    </Routes>

  );
}

function RouteWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
}

export default App;